import React, { useState, useEffect, } from "react";
import { getDoc, getDocs, doc, setDoc, updateDoc, onSnapshot, collection, addDoc, Timestamp } from 'firebase/firestore';
import { useNavigate, useParams, Link, useHistory, useNavigation } from 'react-router-dom';
import { getAuth, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { FaUser, FaArrowLeft, FaTv } from 'react-icons/fa';
import { db, app } from "../../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { Menu } from "./AccueilAdmin";
import Logo from '../../assets/logo/logo-horizontale-v2.png';
import { useTranslation } from 'react-i18next';


export function Organisation() {
    const { t } = useTranslation();
    const [displayOrga, setDisplayOrga] = useState(0); // 1 = combat passé, 2 = combat à venir
    /////// BOUTON
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };


    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };

    return (

        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#fcfaf5"
        }}>
            <div style={{
                position: 'absolute', top: '0', height: '60px', width: '100%', display: 'flex',
                justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#ff3030', padding: '0 20px'
            }}>
                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <img src={Logo} alt="Logo" style={{ height: '250px' }} />
                    <h2 style={{ margin: 0, color: 'white', fontSize: '1.2em' }}>ORGANIZATION</h2>
                </div>

                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <button onClick={toggleMenu} style={{
                        padding: '8px 16px', border: 'none', backgroundColor: 'white', color: '#ff3030',
                        borderRadius: '25px', cursor: 'pointer', outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginRight: '10px'
                    }}>
                         {isMenuOpen ? t('Close the menu') : t('Open the menu')}
                    </button>

                    <button onClick={handleLogout} style={{
                        background: 'none', border: '1px solid white', color: 'white', padding: '8px 16px',
                        borderRadius: '25px', cursor: 'pointer', fontWeight: 'bolder'
                    }}>
                        Logout
                    </button>
                </div>
            </div>


            {isMenuOpen && (
                <Menu />
            )}
            <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%'
            }}>

                <div style={{ // DONNÉE FIGHTER
                    marginTop: '100px', width: '60%', alignItems: 'center', justifyContent: 'center',
                }}>

                    <div style={{ // BOUTONS
                        flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
                    }}>
                        <button // 
                            onClick={() => setDisplayOrga(1)}
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                                color: displayOrga === 1 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                height: 50, width: '45%', fontSize: 15, backgroundColor: displayOrga === 1 ? '#ff3030' : 'white',

                            }}>
                            {t("ADD AN ORGANIZATION")}
                        </button>

                        <button // 
                            onClick={() => setDisplayOrga(2)}
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                                color: displayOrga === 2 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                height: 50, width: '45%', fontSize: 15, backgroundColor: displayOrga === 2 ? '#ff3030' : 'white', marginLeft: 20
                            }}>
                            {t("UPDATE AN ORGANIZATION")}
                        </button>
                    </div>

                    {displayOrga === 1 ? <SubmitOrga /> : null}
                    {displayOrga === 2 ? <UpdateOrga /> : null}


                </div>
            </div>
        </div>
    )
}

export function SubmitOrga() {
    const { t } = useTranslation();
    const [eventName, setEventName] = useState("");
    const [mainevent, setMainevent] = useState("");
    const [org, setOrg] = useState("");
    const [color, setColor] = useState('');
    const [colorEvent, setColorEvent] = useState('');
    const [orgaLogo, setOrgaLogo] = useState('');
    const [orgaFullName, setOrgaFullName] = useState('');


    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const articleRef = doc(db, "fightData", "organisations", org, 'events');
            await setDoc(articleRef, {
                color,
                colorEvent,
                id: org,
                logo: orgaLogo,
                name: orgaFullName
            });

            setColor(''); setColorEvent(''); setOrg(''); setOrgaLogo(''); setOrgaFullName('');
            alert("ORGANISATION soumis avec succès !");
        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };

    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("Submit an organization")}</h2>


                <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <div style={{ // ORGA ID
                        width: '100%',
                    }}>
                        <label>ID ORGANIZATION :</label>

                        <div style={{
                            marginBottom: '10px', flexDirection: 'column', display: 'flex',
                        }}>
                            <input
                                type="text"
                                name="place"
                                value={org}
                                onChange={(e) => setOrg(e.target.value)}
                                required
                                style={{
                                    width: '100%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }} />


                        </div>


                    </div>

                    <div style={{ // ORGA LOGO
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>ORGANIZATION LOGO :</label>

                        <div style={{
                            width: '100%', marginBottom: '10px'
                        }}>

                            <input
                                type="text"
                                value={orgaLogo}
                                onChange={(e) => setOrgaLogo(e.target.value)}
                                required
                                style={{
                                    width: '70%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }}
                            />
                        </div>
                    </div>

                    <div style={{ // ORGA FULL NAME
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("FULL NAME ORGANIZATION")} :</label>

                        <div style={{
                            width: '100%', marginBottom: '10px'
                        }}>

                            <input
                                type="text"
                                value={orgaFullName}
                                onChange={(e) => setOrgaFullName(e.target.value)}
                                required
                                style={{
                                    width: '70%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }}
                            />
                        </div>
                    </div>

                    <div style={{ // COLOR
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("COLOR")} :</label>

                        <div style={{
                            width: '100%', marginBottom: '10px'
                        }}>

                            <input
                                type="text"
                                value={color}
                                onChange={(e) => setColor(e.target.value)}
                                required
                                style={{
                                    width: '70%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }}
                            />
                        </div>
                    </div>

                    <div style={{ // COLOR EVENT
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("COLOR FOR EVENT")} :</label>

                        <div style={{
                            width: '100%', marginBottom: '10px'
                        }}>

                            <input
                                type="text"
                                value={colorEvent}
                                onChange={(e) => setColorEvent(e.target.value)}
                                required
                                style={{
                                    width: '70%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }}
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                            color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                        }}
                    >
                        {t("Submit")}
                    </button>

                </form>
            </div>
        </div>

    );
}

export function UpdateOrga() {
    const { t } = useTranslation();
    const [orgs, setOrgs] = useState([]);
    const [org, setOrg] = useState("");
    const [color, setColor] = useState('');
    const [colorEvent, setColorEvent] = useState('');
    const [orgaLogo, setOrgaLogo] = useState('');
    const [orgaFullName, setOrgaFullName] = useState('');
    const [orgaData, setOrgaData] = useState([]);
    const [formulaireVisible, setFormulaireVisible] = useState(false);

    useEffect(() => { //  orga data
        setColor(orgaData ? orgaData.color : null);
        setColorEvent(orgaData ? orgaData.colorEvent : null);
        setOrgaLogo(orgaData ? orgaData.logo : null);
        setOrgaFullName(orgaData ? orgaData.name : null);
    }, [orgaData]);

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    useEffect(() => { // RÉCUPÉRATION orga data
        const fetchData = async () => {
            if (org) { // Si une organisation est sélectionnée
                const docRef = doc(db, "fightData", "organisations", org, "events");
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setOrgaData(data);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchData();
    }, [org]);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const orgRef = doc(db, "fightData", "organisations", org, 'events');
            // Obtenir le snapshot du document
        const docSnap = await getDoc(orgRef);

        // Vérifier si le document existe et mettre à jour ou créer en conséquence
        if (docSnap.exists()) {
            await updateDoc(orgRef, {
                color,
                colorEvent,
                id: org,
                logo: orgaLogo,
                name: orgaFullName
            });
        } else {
            await setDoc(orgRef, {
                color,
                colorEvent,
                id: org,
                logo: orgaLogo,
                name: orgaFullName
            });
        }
            

            setColor(''); setColorEvent(''); setOrg(''); setOrgaLogo(''); setOrgaFullName(''); setFormulaireVisible(false);
            alert("ORGANISATION soumis avec succès !");
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };


    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("UPDATE AN ORGANIZATION")}</h2>


                <div style={{ backgroundColor: '#ff3030', padding: '20px', borderRadius: 10 }}>

                    <div style={{ // Organisation
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Organization :</label>
                        <select
                            value={org}
                            onChange={(e) => {
                                setOrg(e.target.value); if (e.target.value !== "") {
                                    setFormulaireVisible(true);
                                }
                            }}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an organization")}</option>
                            {orgs.map((org, index) => (
                                <option key={index} value={org}>
                                    {org}
                                </option>
                            ))}
                        </select>
                    </div>


                </div>

                {formulaireVisible ?
                    <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>

                        <div style={{ // ORGA LOGO
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>ORGANIZATION LOGO :</label>

                            <div style={{
                                width: '100%', marginBottom: '10px'
                            }}>

                                <input
                                    type="text"
                                    value={orgaLogo}
                                    onChange={(e) => setOrgaLogo(e.target.value)}
                                    required
                                    style={{
                                        width: '70%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ // ORGA FULL NAME
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("FULL NAME ORGANIZATION")} :</label>

                            <div style={{
                                width: '100%', marginBottom: '10px'
                            }}>

                                <input
                                    type="text"
                                    value={orgaFullName}
                                    onChange={(e) => setOrgaFullName(e.target.value)}
                                    required
                                    style={{
                                        width: '70%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ // COLOR
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("COLOR")} :</label>

                            <div style={{
                                width: '100%', marginBottom: '10px'
                            }}>

                                <input
                                    type="text"
                                    value={color}
                                    onChange={(e) => setColor(e.target.value)}
                                    required
                                    style={{
                                        width: '70%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ // COLOR EVENT
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("COLOR FOR EVENT")} :</label>

                            <div style={{
                                width: '100%', marginBottom: '10px'
                            }}>

                                <input
                                    type="text"
                                    value={colorEvent}
                                    onChange={(e) => setColorEvent(e.target.value)}
                                    required
                                    style={{
                                        width: '70%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }}
                                />
                            </div>
                        </div>

                        <button
                            type="submit"
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                            }}
                        >
                            {t("Submit")}
                        </button>

                    </form>
                    : null}
            </div>
        </div>

    );
}