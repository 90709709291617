import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'intl-pluralrules';

const resources = {
  en: {
    translation: require('./locales/en/translation.json'),
  },
  fr: {
    translation: require('./locales/fr/translation.json'),
  },
  es: {
    translation: require('./locales/es/translation.json'),
  },
  de: {
    translation: require('./locales/de/translation.json'),
  },
  // ajoutez d'autres langues ici...
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: 'en', // utilisez 'en' ou n'importe quelle langue que vous voulez comme langue par défaut
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;