// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA7qmaCtWMFnZzCBevXcrlt5l32IeVN_iU",
  authDomain: "fightscore-a65dc.firebaseapp.com",
  projectId: "fightscore-a65dc",
  storageBucket: "fightscore-a65dc.appspot.com",
  messagingSenderId: "300524200968",
  appId: "1:300524200968:web:2ed3a103b1e09ebb801dbd",
  measurementId: "G-0G77D8CKCK",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
export { auth, db, app, storage };
// src/firebase.js
// ...


//chercher dans la database
/*
const docRef = doc(db, "UFC", "4welter", "men", "Diaz Nate");
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
console.log("Document data:", docSnap.data());
} else {
// doc.data() will be undefined in this case
console.log("No such document!");
}
*/