import React, { useState, useEffect, } from "react";
import { getDoc, getDocs, doc, setDoc, updateDoc, onSnapshot, collection, addDoc, } from 'firebase/firestore';
import { useNavigate, useParams, Link, useHistory, useNavigation } from 'react-router-dom';
import { getAuth, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { FaUser, FaArrowLeft, FaTv } from 'react-icons/fa';
import { db, app } from "../../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/logo/logo-horizontale-v2.png';
import { useAdminRole } from "../../App";
import { useTranslation } from 'react-i18next';


export function AccueilAdmin() {
    const navigate = useNavigate();
    const auth = getAuth();
    const { t } = useTranslation();
    const { adminRole, setAdminRole, email, setEmail } = useAdminRole();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };

    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                position: 'absolute', top: '0px', height: '50px', width: '100%', display: 'flex',
                justifyContent: 'center', alignItems: 'center', backgroundColor: '#ff3030'
            }}>
                <div style={{
                    position: 'absolute', left: 0, height: '50px', left: '20px', display: 'flex', alignItems: 'center'
                }}> <img src={Logo} alt="Logo" style={{ height: '250px' }} /></div>

                <h2 style={{ margin: 0, color: 'white' }}>Home - Admin</h2>

                <button onClick={handleLogout} style={{ marginLeft: '20px', background: 'none', border: 'none', color: 'white', cursor: 'pointer', fontWeight: 'bolder' }}>
                    Logout
                </button>
            </div>

            <div style={{
                marginTop: '10px', position: 'absolute', top: '50px',
                display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}>
                {adminRole === 'admin' ?  
                <div style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px',
                    fontFamily: "'Arial', sans-serif", borderRadius: '10px', width: '400px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: '#ffffff'
                }}>
                    {['Fighter', 'EVENT', 'Combat', 'Organization', 'Rankings', 'live'].map((item, index) => (
                        <button key={index} onClick={() => { navigate(`/${item}`) }} style={{
                            padding: '10px 20px', border: 'none', backgroundColor: item === 'live' ? '#0ee802' : '#ff3030',
                            color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            width: '100%', textAlign: 'center'
                        }}>
                            {item.toUpperCase()}
                        </button>
                    ))}
                </div>
                : adminRole === 'CM' ? <div style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px',
                    fontFamily: "'Arial', sans-serif", borderRadius: '10px', width: '400px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: '#ffffff'
                }}>
                    {['Fighter'].map((item, index) => (
                        <button key={index} onClick={() => { navigate(`/${item}`) }} style={{
                            padding: '10px 20px', border: 'none', backgroundColor: item === 'live' ? '#0ee802' : '#ff3030',
                            color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            width: '100%', textAlign: 'center'
                        }}>
                            {item.toUpperCase()}
                        </button>
                    ))}
                </div> : null}
            </div>
        </div>
    );
}

export function Menu() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { adminRole, setAdminRole, email, setEmail } = useAdminRole();
    return (
        <div style={{
            marginTop: '10px', position: 'absolute', top: '50px',
            display: 'flex', justifyContent: 'center', alignItems: 'center', right: 0
        }}>
             {adminRole === 'admin' ?  
                <div style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px',
                    fontFamily: "'Arial', sans-serif", borderRadius: '10px', width: '400px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: '#ffffff'
                }}>
                    {['Fighter', 'EVENT', 'Combat', 'Organisation', 'Rankings', 'live'].map((item, index) => (
                        <button key={index} onClick={() => { navigate(`/${item}`) }} style={{
                            padding: '10px 20px', border: 'none', backgroundColor: item === 'live' ? '#0ee802' : '#ff3030',
                            color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            width: '100%', textAlign: 'center'
                        }}>
                            {item.toUpperCase()}
                        </button>
                    ))}
                </div>
                : adminRole === 'CM' ? <div style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px',
                    fontFamily: "'Arial', sans-serif", borderRadius: '10px', width: '400px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: '#ffffff'
                }}>
                    {['Fighter'].map((item, index) => (
                        <button key={index} onClick={() => { navigate(`/${item}`) }} style={{
                            padding: '10px 20px', border: 'none', backgroundColor: item === 'live' ? '#0ee802' : '#ff3030',
                            color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            width: '100%', textAlign: 'center'
                        }}>
                            {item.toUpperCase()}
                        </button>
                    ))}
                </div> : null}
        </div>
    )
}